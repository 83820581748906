import React from 'react'
import { motion, Variants } from 'framer-motion'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { Icon } from './Icon'
import { GLOBAL_MAP_QUERYSTRING } from '@/components/Forms/constants'

export interface LegendItem {
  color: string
  icon: string
  id: string
  title: string
}

interface Props {
  activeItem: LegendItem
  showAll: boolean
  transitionDuration: number
  setSelectedLocation: (id: string) => void
  setActiveItem: (item: LegendItem) => void
  setShouldAnimate: (shouldAnimate: boolean) => void
  activeIndex: React.MutableRefObject<number>
}

export const legendItems = [
  {
    color: '#49b56b',
    icon: 'R',
    id: 'shiprepair',
    title: 'Damen Shiprepair & Conversion',
  },
  {
    color: '#ff7c52',
    icon: 'Y',
    id: 'newbuild',
    title: 'Damen Newbuild Yard',
  },
  {
    color: '#2546b1',
    icon: 'D',
    id: 'damencompany',
    title: 'Damen Company',
  },
  {
    color: 'white',
    icon: 'S',
    id: 'servicehubs',
    title: 'Damen Service Hubs',
  },
]

export const variants: Variants = { hidden: { opacity: 0.3 }, visible: { opacity: 1 } }

const Legend: React.FC<React.PropsWithChildren<Props>> = ({
  activeItem,
  transitionDuration,
  showAll,
  setSelectedLocation,
  setActiveItem,
  setShouldAnimate,
  activeIndex,
}) => {
  const router = useRouter()

  return (
    <Container>
      {legendItems.map(({ color, icon, id, title }) => (
        <StyledMotionDiv
          animate={activeItem?.id === id || showAll ? 'visible' : 'hidden'}
          key={id}
          transition={{ duration: transitionDuration }}
          variants={variants}
          onClick={() => {
            setSelectedLocation(title)
            return void router.push({ hash: GLOBAL_MAP_QUERYSTRING }, undefined, { shallow: true })
          }}
          onHoverStart={() => {
            setShouldAnimate(false)
            setActiveItem(legendItems.find((x) => x.id === id))
          }}
          onHoverEnd={() => {
            setShouldAnimate(true)
            // property is assignable, but shows an error therfore the disable eslint
            // eslint-disable-next-line no-param-reassign
            activeIndex.current = legendItems.findIndex((x) => x.id === id)
          }}
        >
          <Icon color={color}>{icon}</Icon>
          {title}
        </StyledMotionDiv>
      ))}
    </Container>
  )
}

const Container = styled.div`
  display: none;

  @media ${({ theme }) => theme.legacyMediaQueries.md} {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin: 0 0 80px;
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => theme.typography.fontSizeTextSmall}px;
  }

  @media ${({ theme }) => theme.legacyMediaQueries.lg} {
    margin: 0 0 100px;
  }
`

const StyledMotionDiv = styled(motion.div)`
  &:hover {
    cursor: pointer;
  }
`

export default Legend
