import styled from 'styled-components'

export const Icon = styled.span.withConfig({
  shouldForwardProp: (prop) => !['color'].includes(prop),
})<{ color: string }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin: 0 8px 16px 0;
  border-radius: 16px;
  background-color: ${({ color }) => color};
  color: ${({ color, theme }) => (color !== 'white' ? 'inherit' : theme.colors.marineBlack)};
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  line-height: 1;
`
