import React, { useEffect, useRef, useState } from 'react'
import { Button, Heading, Icon } from '@damen/ui'
import { motion, Variants } from 'framer-motion'
import styled from 'styled-components'
import { useReactiveVar } from '@apollo/client'
import { useInView } from 'react-intersection-observer'
import { useRouter } from 'next/router'

import dynamic from 'next/dynamic'
import { ContainerVertical, Modal } from '@/components/UI'

import { Spacing } from '@/types'

import DamenCompany from './DamenCompany.svg'
import Legend, { LegendItem, legendItems } from './Legend'
import Map from './Map.svg'
import NewBuild from './NewBuild.svg'
import ServiceHubs from './ServiceHubs.svg'
import ShipRepair from './ShipRepair.svg'

import { showGlobalMapModal } from '@/lib/apollo/useApollo'

// Dynamic import
const InteractiveMap = dynamic(() => import('@/components/Map'), { ssr: false })

interface Content {
  id: string
  spacing: Spacing
  title: string
  buttonText?: string
  prefilteredLocations?: string
}

interface Props {
  content: Content
}

const variants: Variants = { hidden: { opacity: 0 }, visible: { opacity: 1 } }

const transitionDuration = 0.7
const GLOBAL_MAP_QUERYSTRING = 'global_map'

const Global: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const router = useRouter()
  const [shouldAnimate, setShouldAnimate] = useState(false)
  const [activeItem, setActiveItem] = useState<LegendItem | null>(null)
  const [selectedLocation, setSelectedLocation] = useState<string>(null)
  const [ref, inView] = useInView({ rootMargin: '-50%' })

  const activeIndex = useRef(0)

  const { spacing, title, buttonText } = content

  const showGlobalMap = useReactiveVar(showGlobalMapModal)

  const intervalRef = useRef(null)

  useEffect(() => {
    const animate = () => {
      setActiveItem(legendItems[activeIndex.current])

      if (activeIndex.current === legendItems.length) {
        activeIndex.current = 0
        return
      }

      activeIndex.current += 1
    }

    if (shouldAnimate) {
      animate()
      intervalRef.current = setInterval(animate, 2000)
    }

    return () => {
      clearInterval(intervalRef.current)
      intervalRef.current = null
    }
  }, [shouldAnimate])

  useEffect(() => {
    setShouldAnimate(inView && !showGlobalMap)
  }, [inView, showGlobalMap])

  const closeGlobalMapModel = () => {
    if (router.asPath.includes(GLOBAL_MAP_QUERYSTRING)) {
      router.back()
    }
  }

  return (
    <ContainerVertical verticalSpacing={spacing}>
      <Container ref={ref}>
        <DetailsWrapper>
          <Details>
            <DetailsHeader>
              <Heading.HeadingTwo>{title}</Heading.HeadingTwo>
              {buttonText && (
                <Button.Default
                  icon={Icon.LeftChevron}
                  text={buttonText}
                  onClick={() => {
                    return void router.push({ hash: GLOBAL_MAP_QUERYSTRING }, undefined, { shallow: true })
                  }}
                />
              )}
            </DetailsHeader>
            <Legend
              activeItem={activeItem}
              showAll={!activeItem}
              transitionDuration={transitionDuration}
              setSelectedLocation={setSelectedLocation}
              setActiveItem={setActiveItem}
              setShouldAnimate={setShouldAnimate}
              activeIndex={activeIndex}
            />
          </Details>
        </DetailsWrapper>
        <svg xmlns="http://www.w3.org/2000/svg" id="svg-map" data-name="svg map" viewBox="0 0 950 620">
          <g id="svgmap">
            <Map />
          </g>

          <motion.g
            animate={activeItem?.id === 'shiprepair' || !activeItem ? 'visible' : 'hidden'}
            id="shiprepair"
            initial={{ opacity: 0 }}
            transition={{ duration: transitionDuration }}
            variants={variants}
          >
            <ShipRepair />
          </motion.g>

          <motion.g
            animate={activeItem?.id === 'newbuild' || !activeItem ? 'visible' : 'hidden'}
            id="newbuild"
            initial={{ opacity: 0 }}
            transition={{ duration: transitionDuration }}
            variants={variants}
          >
            <NewBuild />
          </motion.g>

          <motion.g
            animate={activeItem?.id === 'damencompany' || !activeItem ? 'visible' : 'hidden'}
            id="damencompany"
            initial={{ opacity: 0 }}
            transition={{ duration: transitionDuration }}
            variants={variants}
          >
            <DamenCompany />
          </motion.g>

          <motion.g
            animate={activeItem?.id === 'servicehubs' || !activeItem ? 'visible' : 'hidden'}
            id="servicehubs"
            initial={{ opacity: 0 }}
            transition={{ duration: transitionDuration }}
            variants={variants}
          >
            <ServiceHubs />
          </motion.g>
        </svg>
      </Container>
      <Modal.Overlay isOpen={showGlobalMap} onClose={closeGlobalMapModel} hasPadding={false}>
        {showGlobalMap && <InteractiveMap prefilteredLocations={selectedLocation ?? content.prefilteredLocations} />}
      </Modal.Overlay>
    </ContainerVertical>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;

  @media ${({ theme }) => theme.legacyMediaQueries.mdMax} {
    padding: 0 16px;
  }

  #svg-map {
    max-height: 820px;
    width: 100%;
    background: ${({ theme }) => theme.colors.marineBlack};
  }

  .cls-1 {
    fill: #2d3e68;
  }

  .cls-2 {
    fill: #49b56b;
  }

  .cls-3 {
    fill: #fff;
  }

  .cls-4 {
    fill: #ff7c52;
  }

  .cls-5 {
    fill: #2546b1;
  }

  .cls-6 {
    fill: #011736;
  }

  .text-1 {
    font-size: 4px;
    text-anchor: middle;
    dominant-baseline: middle;
    fill: white;
  }

  .text-2 {
    font-size: 4px;
    text-anchor: middle;
    dominant-baseline: middle;
    fill: #011736;
  }
`

const DetailsWrapper = styled.div`
  @media ${({ theme }) => theme.legacyMediaQueries.md} {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`

const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 1208px;
  height: 100%;

  @media ${({ theme }) => theme.legacyMediaQueries.md} {
    padding: 0 80px;
  }

  @media ${({ theme }) => theme.legacyMediaQueries.md} {
    padding: 0 120px;
  }

  @media (min-width: 1440px) {
    padding: 0;
    margin: 0 auto;
  }

  h2 {
    max-width: 374px;
    color: ${({ theme }) => theme.colors.marineBlack};

    @media ${({ theme }) => theme.legacyMediaQueries.md} {
      margin: 80px 0 0;
      color: ${({ theme }) => theme.colors.white};
    }

    @media ${({ theme }) => theme.legacyMediaQueries.lg} {
      margin: 100px 0 0;
    }
  }
`

const DetailsHeader = styled.div`
  margin-bottom: ${({ theme }) => theme.layout.spacingM};

  h2 {
    margin-bottom: ${({ theme }) => theme.layout.spacingM};
  }
`

export default Global
